import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css'; // 전역 CSS 파일을 임포트합니다.
import LoginPage from './components/LoginPage';
import Elevator from './components/Elevator';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route path="/elevator" element={<Elevator />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;