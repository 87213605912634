// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 로그인 페이지 스타일링 */
.login-container {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) ;
    background: rgba(80, 80, 80, 0.8); /* 검은색 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  
  .login-form {
    background: rgba(80, 80, 80, 0.8); /* 검은색 배경 */
    color: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .title {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .input-group input {
    width: 90%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #333;
    color: white;
  }
  
  button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }`, "",{"version":3,"sources":["webpack://./src/components/LoginPage.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,oDAA0C;IAC1C,iCAAiC,EAAE,WAAW;IAC9C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;EAC7B;;EAEA;IACE,iCAAiC,EAAE,WAAW;IAC9C,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,uCAAuC;EACzC;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* 로그인 페이지 스타일링 */\n.login-container {\n    background: url('/public/background.jpg') ;\n    background: rgba(80, 80, 80, 0.8); /* 검은색 배경 */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-size: cover;\n    background-position: center;\n  }\n  \n  .login-form {\n    background: rgba(80, 80, 80, 0.8); /* 검은색 배경 */\n    color: white;\n    padding: 20px;\n    border-radius: 10px;\n    width: 100%;\n    max-width: 400px;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);\n  }\n  \n  .title {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  \n  .input-group {\n    margin-bottom: 15px;\n  }\n  \n  .input-group label {\n    display: block;\n    margin-bottom: 5px;\n    font-size: 14px;\n  }\n  \n  .input-group input {\n    width: 90%;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    background: #333;\n    color: white;\n  }\n  \n  button {\n    width: 100%;\n    padding: 10px;\n    border: none;\n    border-radius: 5px;\n    background: #007bff;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background: #0056b3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
