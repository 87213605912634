import React, { useState, useEffect, useRef } from 'react';
import './Elevator.css'; // CSS 파일 임포트

const Elevator = () => {
  const [currentFloor, setCurrentFloor] = useState('1');
  const [elevatorState, setElevatorState] = useState('정지');
  const [doorState, setDoorState] = useState('close');
  const [calledUp, setCalledUp] = useState(false);
  const [calledDown, setCalledDown] = useState(false);
  const [calledToFirstFloor, setCalledToFirstFloor] = useState(false);
  const [calledToB1Floor, setCalledToB1Floor] = useState(false);
  const userFloor = '8'; // 사용자 층수는 문자열로 유지

  // useRef를 사용하여 최신 상태 값을 유지
  const calledUpRef = useRef(calledUp);
  const calledDownRef = useRef(calledDown);
  const calledToFirstFloorRef = useRef(calledToFirstFloor);
  const calledToB1FloorRef = useRef(calledToB1Floor);

  // calledUp 상태가 변경될 때마다 calledUpRef 업데이트
  useEffect(() => {
    calledUpRef.current = calledUp;
  }, [calledUp]);

  // calledDown 상태가 변경될 때마다 calledDownRef 업데이트
  useEffect(() => {
    calledDownRef.current = calledDown;
  }, [calledDown]);

  // calledToFirstFloor 상태가 변경될 때마다 calledDownRef 업데이트
  useEffect(() => {
    calledToFirstFloorRef.current = calledToFirstFloor;
  }, [calledToFirstFloor]);

  // calledToB1Floor 상태가 변경될 때마다 calledDownRef 업데이트
  useEffect(() => {
    calledToB1FloorRef.current = calledToB1Floor;
  }, [calledToB1Floor]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchElevatorData();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchElevatorData = async () => {
    try {
      const response = await fetch('https://api.stohm.kr/apt/wallpad/elve/status');
      const data = await response.json();
      const item = data.root.item;

      // way 값 처리
      let state = '정지';
      if (item.way === '0') state = '정지';
      else if (item.way === '1') state = '상승 중';
      else if (item.way === '2') state = '하강 중';

      setCurrentFloor(item.floor);
      setElevatorState(state);
      setDoorState(item.doorState);

      // 엘리베이터가 현재 층에 도착하고 문이 열리면 호출된 방향의 버튼 상태를 해제
      if (item.floor === userFloor && item.doorState === 'open') {
        if (calledUpRef.current && item.way === '1') {
          setCalledUp(false);
        }
        if (calledDownRef.current && item.way === '2') {
          setCalledDown(false);
        }
      }
      else if (item.floor === '1' && item.doorState === 'open' && calledToFirstFloorRef.current && item.way === '1') {
        setCalledToFirstFloor(false);
      }
      else if (item.floor === 'B1' && item.doorState === 'open' && calledToB1FloorRef.current && item.way === '1') {
        setCalledToB1Floor(false);
      }
      
    } catch (error) {
      console.error('Error fetching elevator data:', error);
    }
  };

  const handleCallButtonClick = (direction) => {
    if (direction === 'up' && !calledUp) {
      setCalledUp(true);
      callElevator(direction);
    } else if (direction === 'down' && !calledDown) {
      setCalledDown(true);
      callElevator(direction);
    } else if (direction === 1 && !calledToFirstFloor) {
      setCalledToFirstFloor(true);
      callElevatorToFloor(direction);
    } else if (direction === 0 && !calledToB1Floor) {
      setCalledToB1Floor(true);
      callElevatorToFloor(direction);
    }

    console.log(calledUpRef.current)
  };

  // 엘베 호출하기
  const callElevator = async (direction) => {
    try {
      const way = direction === 'up' ? '1' : '2';
      const response = await fetch(`https://api.stohm.kr/apt/wallpad/elve/call?way=${way}`, {
        method: 'GET', // GET 요청으로 설정
      });

      if (!response.ok) {
        throw new Error('Failed to call elevator');
      }

      console.log(`Elevator called to floor ${userFloor} (${direction})`);
    } catch (error) {
      console.error('Error calling elevator:', error);
    }
  };

  // 방향 표시
  const getDirectionArrow = () => {
    if (elevatorState === '상승 중') return '▲';
    if (elevatorState === '하강 중') return '▼';
    return '-';
  };
  
  // 작은 버튼을 눌렀을 때 호출하는 함수
  const callElevatorToFloor = async (floor) => {
    console.log(`Elevator called to ${floor}`);
    
    // 엘베 호출하기
    try {
      const response = await fetch(`https://api.stohm.kr/apt/gayo/elve/call?floor=${userFloor-floor}`, {
        method: 'GET', // GET 요청으로 설정
      });

      if (!response.ok) {
        throw new Error('Failed to call elevator');
      }

      console.log(`Elevator called to floor ${userFloor} (${floor})`);
    } catch (error) {
      console.error('Error calling elevator:', error);
    }
  };


  return (
    <div
      className={`elevator-container ${
        doorState === 'open' ? 'door-open' : 'door-closed'
      }`}
    >
      <div className="display-panel">
        <div className="floor-display">
          <span className="floor-number">{currentFloor}</span>
          <span className="direction-arrow">{getDirectionArrow()}</span>
        </div>
        <div className="door-status">
          <span className="door-indicator"></span>
          <span className="door-text">{doorState === 'open' ? '열림' : '닫힘'}</span>
        </div>
      </div>
      
      <div className="small-buttons">
        <button
          className={`small-button ${calledToFirstFloor ? 'active' : ''}`}
          onClick={() => handleCallButtonClick(1)}>1층</button>
        <button
          className={`small-button ${calledToB1Floor  ? 'active' : ''}`}
          onClick={() => handleCallButtonClick(0)}>B1층</button>
      </div>

      <div className="call-buttons">
        <button
          className={`call-button up-button ${calledUp ? 'active' : ''}`}
          onClick={() => handleCallButtonClick('up')}
        >
          ▲
        </button>
        <button
          className={`call-button down-button ${calledDown ? 'active' : ''}`}
          onClick={() => handleCallButtonClick('down')}
        >
          ▼
        </button>
      </div>
    </div>
  );
};

export default Elevator;
