// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 전역 스타일링 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000; /* 검은색 배경 */
  color: #fff; /* 흰색 글씨 */
  overflow: hidden; /* 스크롤 제거 */
}

input, button {
  font-family: inherit; /* 일관된 폰트 사용 */
}

button:focus, input:focus {
  outline: none; /* 포커스 아웃라인 제거 */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,SAAS;EACT,8BAA8B;EAC9B,sBAAsB,EAAE,WAAW;EACnC,WAAW,EAAE,UAAU;EACvB,gBAAgB,EAAE,WAAW;AAC/B;;AAEA;EACE,oBAAoB,EAAE,cAAc;AACtC;;AAEA;EACE,aAAa,EAAE,gBAAgB;AACjC","sourcesContent":["/* 전역 스타일링 */\nbody {\n  margin: 0;\n  font-family: Arial, sans-serif;\n  background-color: #000; /* 검은색 배경 */\n  color: #fff; /* 흰색 글씨 */\n  overflow: hidden; /* 스크롤 제거 */\n}\n\ninput, button {\n  font-family: inherit; /* 일관된 폰트 사용 */\n}\n\nbutton:focus, input:focus {\n  outline: none; /* 포커스 아웃라인 제거 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
